@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');

body {
    background-color: #6f6f6f !important;
    height: 100vh;
    background-image: url(../public/verse.png);
    background-size: cover;
    overflow: hidden;
    margin: 0;

    a {
        color: unset;
        text-decoration: none;
    }
}

@import '../public/styles/navbar.scss';
@import '../public/styles/modal.scss';
