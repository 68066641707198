.modal-overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    animation: fade .5s ease-in-out;
    display: flex;
    pointer-events: all;

    .modal-container {
        position: relative;
        margin: auto;
        background-color: #FFFFFF;
        width: 50vw;
        height: fit-content;
        max-width: 1072px;
        max-height: 632px;
        border-radius: 25px;
        padding: 25px;
        padding: 37px 30px;
        animation: appear .5s ease-in-out;
        overflow: hidden;

        &.phantom {
            height: 70vh;
            font-family: Montserrat;

            .collection-modal {
                overflow: scroll;
                height: 97%;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
            
        }

        &.gallery {
            height: 80vh;

            @media only screen and (max-width: 768px) {
                height: 65vh;
            }
        }
        

        @media only screen and (max-width: 768px) {
            width: 90vw;
        }
        
        h3 {
            margin-top: 0;
            margin-bottom: 1em;
        }

        p {
            margin-bottom: 1em;
        }

        .btns-container {
            display: flex;
            justify-content: center;
            margin-top: 10px;
        }

        .btn {
            font-size: 16px;
            cursor: pointer;
            transition: .5s ease all;
            padding: 14px 30px;
            border-radius: 26.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 52px;
            height: 52px;

            @media only screen and (max-width: 376px) {
                font-size: 15px;
                padding: 10px;
            }

            &.confirm {
                background: #000;
                color: #fff;
            }

            &.cancel {
                margin-right: 10px;
            }

            &:hover {
                background: #343434;
                color: #fff;
            }

            &.disabled {
                cursor: no-drop;
                opacity: .5;
            }
        }

        .images-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;
            padding-bottom: 5%;
            max-height: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            .image {
                width: 31%;
                padding-bottom: 40%;
                background-color: #e1e1e1;
                background-position: center;
                background-size: cover;
                margin: 1%;
                border-radius: 5px;
                cursor: pointer;
                transition: .25s ease all;
                &:hover {
                    transform: scale(1.05);
                }

                &.banner {
                    width: 48%;
                    padding-bottom: 30%;
                }
            } 
        }
    }
}

.gallery-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 6.5px;
    position: relative;
    min-height: 200px;
    
    .element {
        background-color: #e1e1e1;
        background-position: center;
        background-size: cover;
        border-radius: 11.517px;
        width: 133px;
        height: 171px;
        box-shadow: 0px -39px 14px 0px rgba(0, 0, 0, 0.25) inset;
        margin: 16.5px 10px;
        margin-left: 0;
        margin-bottom: 0;
        cursor: pointer;
        transition: .25s ease all;
        position: relative;
        animation: appear .25s ease-in-out;

        &:hover {
            transform: scale(1.025);
        }

        .title-card {
            color: #FFF;
            font-size: 15px;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 700;
            margin: 0;
            position: absolute;
            bottom: 0;
            text-align: center;
            padding-bottom: 9px;
            width: 100%;
            text-transform: capitalize;

            border-bottom-left-radius: 11.517px;
            border-bottom-right-radius: 11.517px;
            backdrop-filter: blur(10px);
            padding-top: 9px;
        }

        .badge {
            font-weight: 700;
            font-size: 12.519px;
            font-family: Poppins;
            background-color: #FFA500;
            border-radius: 15.5px;
            color: #FFFFFF;
            text-align: center;
            width: fit-content;
            padding: 6px 26px;
            margin-left: auto;
            margin-right: 0;
            position: absolute;
            top: 9px;
            right: 6px;
        }
    }

    .navigate {
        background-color: #fff;
        border-radius: 50%;
        width: 40.2px;
        height: 40.2px;
        color: #000000;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        transition: .25s ease all;
        cursor: pointer;
        position: absolute;
        top: 45%;
        left: 0;
        z-index: 1;
        display: flex;
        transform: translateX(-50%);

        i {
            margin: auto;
            text-align: center;
            font-size: 25px;
        }

        &:hover {
            background-color: #000000;
            color: #fff;
        }

        &.right {
            right: 0;
            left: unset;
            transform: translateX(50%);
        }

        &.disabled {
            opacity: .3;
            cursor: no-drop;

            &:hover {
                background-color: #fff;
                color: #000;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .collection {
        padding: 30px 16px;

        @media only screen and (max-width: 425px) {
            padding-top: 0 !important;
        }

        .titles-container {
            display: unset;

            .filters-container {
                display: flex;
                -webkit-flex-flow: column-reverse;
                flex-flow: column-reverse;

                .search-container {
                    .pagination {
                        display: none;
                    }

                    input[type="text"] {
                        max-width: unset;
                        width: 100%;
                        margin-top: 19px;
                        margin-bottom: 27px;
                        box-shadow: rgb(100 100 111 / 20%) 0px 3px 15px 0px;
                    }
                    
                }

                .filters-scroll {
                    width: 100%;
                    max-width: 100%;
                    overflow-x: scroll;
                    padding-bottom: 20px;
                    padding-top: 20px;

                    .filters {
                        padding: 0;
                        flex-wrap: unset;
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                }
            }
        }

        .gallery-container {
            .element {
                width: 120.097px;
                height: 153.869px;
                border-radius: 10.378px;
                box-shadow: 0px -48.631919860839844px 39.626007080078125px -13.508866310119629px rgba(0, 0, 0, 0.53) inset;
                margin: auto;
                margin-bottom: 16.5px;
            }
        }

    }
}